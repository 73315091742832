import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  padding: 0 0 2rem 0;
`;

export const Header = styled.header`
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0;
  min-height: 4.25rem;
  box-shadow: ${({ theme }) => theme.shape.shadow.light.m};
  border-bottom: 1px solid #dedce4;

  > div {
    flex: 1 1 auto;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 1.5rem;
  }

  .header_title {
    margin: 0;
  }
`;

export const Logo = styled.img`
  border-right: 2px solid #dedce4;
  padding-right: 1rem;
`;

export const LogoTE = styled.img`
  padding-right: 1rem;
`;

export const Nav = styled.nav`
  margin-left: 2rem;

  a {
    color: ${({ theme }) =>
      theme.palette.schema.brand2.light.hard.default.background};
    text-decoration: none;}

    &:focus,
    &:hover {
      color: ${({ theme }) =>
        theme.palette.schema.brand1.light.hard.default.background};
    }
  }
`;

export const UserNav = styled.div`
  display: flex;
  align-items: center;

  hr {
    width: 2px;
    background: #efefef;
    border: 0;
    height: 1.5rem;
  }
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  max-width: 1400px;
  margin: 2rem auto;
  padding: 0 1.5rem;
  flex: 1 1 auto;
`;

export const Footer = styled.footer`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.palette.text.secondary};
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 1.5rem;
  flex: 1 1 auto;
`;

export const FooterVersion = styled.footer`
  color: ${({ theme }) => theme.palette.text.subtle};
`;
