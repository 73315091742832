import { UserProfile } from '@auth0/nextjs-auth0/client';
import { LoggedUser } from '@models/logged-user';

export const normalizeToLoggedUser = (auth0User: UserProfile): LoggedUser => {
  return {
    name: auth0User.name || '',
    email: auth0User.email || '',
    initials:
      auth0User.name && auth0User.name.split(' ').length > 1
        ? auth0User.name
            .split(' ')
            .slice(0, 2)
            .map((name) => name[0])
            .join('')
        : auth0User.email?.charAt(0) || '',
    avatarUrl: auth0User.picture || null
  };
};
