import { useUser } from '@auth0/nextjs-auth0/client';
import { normalizeToLoggedUser } from './useLoggedUser.normalizer';
import { UseLoggedUser } from './useLoggedUser.types';

/**
 * Returns the logged user info. This hook is intened to be used in the client side.
 * For server-side logged user obtention, look for `getLoggedUser` function, in `@utils/ssr`.
 *
 * @returns the logged user info
 */
export const useLoggedUser: UseLoggedUser = () => {
  const { user: auth0User, isLoading, error } = useUser();

  return {
    isLoading,
    error,
    user: auth0User ? normalizeToLoggedUser(auth0User) : null
  };
};
