import axios from 'axios';
import { HttpClientArgs } from './http.types';
import {
  buildHttpHeaders,
  httpErrorHandler,
  httpResponseHandler
} from './http.utils';

const axiosClient = axios.create({
  timeout: 10000,
  headers: {
    'x-anonymous-id': global.analytics?.user?.().anonymousId() || ''
  }
});

axiosClient.interceptors.response.use(httpResponseHandler, httpErrorHandler);

export const httpClient = {
  get: async <Response>(
    url: string,
    options?: HttpClientArgs
  ): Promise<Response> => {
    return await axiosClient.get(url, {
      headers: buildHttpHeaders(options)
    });
  },

  post: async <Request, Response>(
    url: string,
    data?: Request,
    options?: HttpClientArgs
  ): Promise<Response> => {
    return await axiosClient.post(url, data, {
      headers: buildHttpHeaders(options)
    });
  },

  put: async <Request, Response>(
    url: string,
    data?: Request,
    options?: HttpClientArgs
  ): Promise<Response> => {
    return await axiosClient.put(url, data, {
      headers: buildHttpHeaders(options)
    });
  },

  delete: async <Response>(
    url: string,
    options?: HttpClientArgs
  ): Promise<Response> => {
    return await axiosClient.delete(url, {
      headers: buildHttpHeaders(options)
    });
  },

  deleteWithBody: async <Request, Response>(
    url: string,
    data?: Request,
    options?: HttpClientArgs
  ): Promise<Response> => {
    return await axiosClient.request({
      url,
      data,
      method: 'DELETE',
      headers: buildHttpHeaders(options)
    });
  }
};
