import packageJSON from '@/package.json';
import { LoggedUser } from '@models/logged-user';
import {
  EventTracking,
  PageTrackingArgs,
  PageTrackingEventName
} from './service.event-tracking.types';

const ANALYTICS_NOT_FOUND_MESSAGE =
  '[/services/service.event-tracking] Segment JS API not available. Maybe it has been blocked by an ad blocker?';
const TRACK_LINK_DELAY_IN_MILLIS = 300;
const IDENTIFY_USER_PREFIX = 'userId-';

export const ServiceEventTracking = {
  /**
   * Sends a tracking event to the instrumentation service
   *
   * @param event - The event to be sent
   */
  page: (event: PageTrackingEventName, args: PageTrackingArgs): void => {
    if (typeof global.analytics === 'undefined') {
      console.info(ANALYTICS_NOT_FOUND_MESSAGE);
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const properties = {
      campaign: {
        name: urlParams.get('utm_campaign')?.toLowerCase() || undefined,
        source: urlParams.get('utm_source')?.toLowerCase() || undefined,
        medium: urlParams.get('utm_medium')?.toLowerCase() || undefined,
        term: urlParams.get('utm_term')?.toLowerCase() || undefined,
        content: urlParams.get('utm_content')?.toLowerCase() || undefined,
        id: urlParams.get('utm_id')?.toLowerCase() || undefined
      }
    };

    global.analytics.page(
      event,
      {
        ...args,
        userId: args.userId ? getLoggedUserId(args.userId) : undefined,
        ...properties,
        debug: getDebugInfo()
      },
      {
        context: {
          ...properties
        }
      }
    );
  },

  /**
   * Sends a tracking event to the instrumentation service
   *
   * @param event - The event to be sent
   * @param traits - The user traits to be sent
   */
  track: (
    { type, properties }: EventTracking,
    traits?: Record<string, unknown>
  ): void => {
    if (typeof global.analytics === 'undefined') {
      console.info(ANALYTICS_NOT_FOUND_MESSAGE);
      return;
    }

    global.analytics.track(
      type,
      {
        ...properties,
        debug: getDebugInfo()
      },
      traits
    );
  },

  /**
   * Sends a tracking event to the instrumentation service for a clicked **EXTERNAL** link.
   * It delays the redirection to the given `url` to give some time to the
   * tracking event to land before the redirection takes place.
   *
   * If the redirection is **internal** (using NextJS router), use `ServiceEventTracking.track()`.
   *
   * @param e - Click event
   * @param url - The link target **full URL**. i.e: https://example.com
   * @param event - The event to be sent
   */
  trackExternalLink: (
    e: React.MouseEvent,
    url: string,
    event: EventTracking
  ): void => {
    e.preventDefault();

    ServiceEventTracking.track(event);

    setTimeout(() => {
      window.location.replace(url);
    }, TRACK_LINK_DELAY_IN_MILLIS);
  },

  /**
   * Identifies a logged user againts the instrumentation service.
   *
   * @param user - The logged user
   * @param traits - The user traits to be sent
   */
  identifyUser: (
    user: LoggedUser,
    traits: Record<string, unknown> = {}
  ): void => {
    if (!user.email) return;

    ServiceEventTracking.identify(user.email, traits);
  },

  /**
   * Identifies aid againts the instrumentation service.
   *
   * @param user - The logged user
   * @param traits - The user traits to be sent
   */
  identify: (
    id: number | string,
    traits: Record<string, unknown> = {}
  ): void => {
    if (typeof global.analytics === 'undefined') {
      console.info(ANALYTICS_NOT_FOUND_MESSAGE);
      return;
    }

    global.analytics.identify(getLoggedUserId(id), traits);
  }
};

function getDebugInfo(): { build_version: string; package: string } {
  return { build_version: packageJSON.version, package: packageJSON.name };
}

function getLoggedUserId(id: number | string): string {
  return `${IDENTIFY_USER_PREFIX}${id}`;
}

export default ServiceEventTracking;
