import { HttpError } from '@models/http-error';
import axios, { AxiosResponse } from 'axios';
import { APP_PAGES, getPagePath } from './app-pages';
import { HttpClientArgs } from './http.types';
import { Logger } from './log';

export function buildHttpHeaders(
  options?: HttpClientArgs
): Record<string, string> {
  const opts = options || {};

  const headers = {
    ...opts.headers
  };

  if (opts.bearer) {
    headers.Authorization = `Bearer ${opts.bearer}`;
  }

  return headers;
}

// * Using "any" as return type here consciously
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function httpResponseHandler(response: AxiosResponse): any {
  if (response.status < 400) {
    const data = response?.data;

    if (!data) {
      Logger.warn({
        context: '/utils/http',
        message: 'Response is empty',
        object: response
      });
      return;
    }

    return data;
  }

  throw new HttpError('[/utils/http] Invalid status code');
}

export function httpErrorHandler(error: Error): Promise<never> {
  if (axios.isAxiosError(error)) {
    const status = error?.response?.status;
    const isClientSide = typeof window !== 'undefined';

    // If unauthorized and client-side, redirect to login page
    if (status === 401 && isClientSide) {
      Logger.warn({
        context: '/utils/http',
        message: 'Unauthorized. Redirecting to login page…'
      });

      window.location.replace(
        `${window.location.origin}/${getPagePath(APP_PAGES.logout)}`
      );

      return Promise.reject();
    }

    Logger.error({
      context: 'utils/http',
      message: error.message,
      error
    });

    return Promise.reject(
      new HttpError(error.response?.data.message || error.message, status)
    );
  }

  Logger.error({
    context: 'utils/http',
    message: error.message,
    error
  });

  return Promise.reject(new HttpError(error.message));
}
