import { useLoggedUser } from '@hooks/useLoggedUser';
import { ServiceEventTracking } from '@services/event-tracking';
import { useCallback, useEffect, useRef } from 'react';
import { UsePageTrack } from './usePageTrack.types';

export const usePageTrack: UsePageTrack = (event, args) => {
  const { user, isLoading } = useLoggedUser();
  const alreadySent = useRef(false);

  const trigger = args?.trigger || false;

  useEffect(() => {
    // When user info (if any) is already loaded and event is not tracked yet => Track it
    if (!trigger && !isLoading && !alreadySent.current) {
      const urlParams = new URLSearchParams(window.location.search);

      // Identify user with campaign traits if user is logged in and campaign exists
      if (user?.email && !!urlParams.get('utm_campaign')) {
        identifyUserWithCampaign(user.email, urlParams);
      }

      ServiceEventTracking.page(event, {
        userId: user?.email,
        ...args?.args
      });

      alreadySent.current = true;
    }
  }, [event, user?.email, isLoading, trigger, args]);

  const triggerPageTrack = useCallback(
    (args) => {
      ServiceEventTracking.page(event, {
        userId: user?.email,
        ...args
      });
    },
    [event, user?.email]
  );

  return {
    triggerPageTrack
  };
};

function identifyUserWithCampaign(
  id: number | string,
  urlParams: URLSearchParams
): void {
  ServiceEventTracking.identify(id, {
    'campaign.name': urlParams.get('utm_campaign')?.toLowerCase() || undefined,
    'campaign.source': urlParams.get('utm_source')?.toLowerCase() || undefined,
    'campaign.medium': urlParams.get('utm_medium')?.toLowerCase() || undefined,
    'campaign.term': urlParams.get('utm_term')?.toLowerCase() || undefined,
    'campaign.content':
      urlParams.get('utm_content')?.toLowerCase() || undefined,
    'campaign.id': urlParams.get('utm_id')?.toLowerCase() || undefined
  });
}
