import { Avatar, ButtonLink, Dropdown, Stack } from '@asgard/ui-library';
import { faBuilding, faSignOut } from '@fortawesome/pro-solid-svg-icons';
import { useAppVersion } from '@hooks/api/useAppVersion';
import { useI18n } from '@hooks/useI18n';
import { usePageTrack } from '@hooks/usePageTrack';
import { APP_PAGES, getPagePath } from '@utils/app-pages';
import { getCurrentYear } from '@utils/date';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as S from './Page.styles';
import { PageProps } from './Page.types';

export const Page: React.FC<PageProps> = ({ children, user, pageId }) => {
  const { t } = useI18n('common');
  const { locale } = useRouter();
  const { version, error, isLoading } = useAppVersion();

  usePageTrack('TE Client - Page loaded', {
    trigger: !!pageId,
    args: {
      id: pageId
    }
  });

  return (
    <S.Container>
      <S.Header>
        <Stack
          gap="xl"
          direction="row"
          alignment="center"
          contentAlign="space-between"
        >
          <Stack gap="xl" direction="row" alignment="center">
            <Link passHref href={getPagePath(APP_PAGES.home, locale)}>
              <a>
                <S.Logo
                  src="https://media-asgard.s3.eu-west-1.amazonaws.com/manfred-media/logo_manfred_color.svg"
                  width="150px"
                />
              </a>
            </Link>
            <Link passHref href={getPagePath(APP_PAGES.home, locale)}>
              <a>
                <S.LogoTE
                  src="https://talent-engine-media.s3.eu-west-1.amazonaws.com/assets/logo_talent_engine_color.svg"
                  alt={t('common.page-title')}
                  width="175px"
                />
              </a>
            </Link>
          </Stack>

          {user && (
            <S.UserNav>
              <Stack alignment="center" gap="s">
                <Link passHref href={getPagePath(APP_PAGES.company, locale)}>
                  <ButtonLink
                    label="Mi empresa"
                    startIcon={faBuilding}
                    size="xs"
                  />
                </Link>
              </Stack>

              <hr />

              <Dropdown
                size="xs"
                variant="bare"
                position="right"
                options={[
                  {
                    asLink: true,
                    icon: faSignOut,
                    href: getPagePath(APP_PAGES.logout, locale),
                    label: t('common.logout'),
                    id: 'logout'
                  }
                ]}
                label={
                  <>
                    {user.avatarUrl ? (
                      <Avatar
                        img={{
                          src: user.avatarUrl,
                          alt: user.name
                        }}
                      />
                    ) : (
                      <Avatar
                        text={{
                          text: user.initials
                        }}
                      />
                    )}
                  </>
                }
              />
            </S.UserNav>
          )}
        </Stack>
      </S.Header>

      <S.Content>{children}</S.Content>

      <S.Footer>
        © {getCurrentYear()} <strong>Manfred</strong> Tech S.L.U.
        {!isLoading && !error && version && (
          <S.FooterVersion>
            {t('common.appVersion', { version: version.appVersion })}
            <br />
            {t('common.apiVersion', { version: version.apiVersion })}
          </S.FooterVersion>
        )}
      </S.Footer>
    </S.Container>
  );
};
