import { useQuery } from '@asgard/ui-common';
import { HttpError } from '@models/http-error';
import { Version } from '@models/version';
import { ServiceVersion } from '@services/version';
import { USE_VERSION_KEY } from './useAppVersion.constants';
import { UseAppVersion } from './useAppVersion.types';

export const useAppVersion: UseAppVersion = () => {
  const {
    data: version,
    isLoading,
    error
  } = useQuery<Version, HttpError>(USE_VERSION_KEY, async () => {
    return ServiceVersion.get();
  });

  return {
    version,
    isLoading,
    error
  };
};
